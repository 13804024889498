
import moment from "moment";
import { defineComponent, ref, computed, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "notifications-list",
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const loading = ref<boolean>(true);
    const notificationsList = ref<any[]>([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalItems = ref(0);

    const paginatedNotifications = computed(() => {
      const start = (currentPage.value - 1) * pageSize;
      const end = start + pageSize;
      return notificationsList.value.slice(start, end);
    });

    const getNotificationLink = (notif: any) => {
      switch (notif.refType) {
        case "newHomework":
          return "/devoirs";
        case "newCourse":
          return "/cours";
        case "newBulletin":
          return "/bulletin";
        case "newEmploi":
          return "/emplois";
        case "StudentPunishmentAccepted":
          return "/";
        case "adminMessage":
          return "/chat";
        case "newActuality":
          return "/actualites";
        case "newComment":
          return "/actualites";
        case "newEvent":
          return "/calendar";
        case "newPayment":
          return "/paiement";
        case "newRegistration":
          return "/";
        case "attendance":
          return "/attendance";
        default:
          return "/";
      }
    };

    const loadNotifications = async () => {
      loading.value = true;
      try {
        const { data } = await ApiService.get("/notifications");
        const mappedData = data.notifications.map((notif: any) => ({
          ...notif,
          link: getNotificationLink(notif),
        }));

        notificationsList.value = mappedData;
        totalItems.value = mappedData.length;

        await ApiService.post("/notifications/mark-as-read", {});
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        loading.value = false;
      }
    };

    const handleCurrentChange = (val: number) => {
      currentPage.value = val;
    };

    onMounted(async () => {
      await loadNotifications();
    });

    return {
      t,
      moment,
      loading,
      paginatedNotifications,
      currentPage,
      totalItems,
      handleCurrentChange,
      apiUrl: store.getters.serverConfigUrl.base_url + "/",
    };
  },
});
