
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import { event } from "vue-gtag";

interface ProfileDetails {
  father: {
    firstName: string;
    lastName: string;
    phone: string;
    job: string;
  };
  mother: {
    firstName: string;
    lastName: string;
    phone: string;
    job: string;
  };
  adress: string;
  email: string;
  status: string;
}

interface ChildDetails {
  firstName: string;
  lastName: string;
  BirthDate: string;
  phone: string;
  adress: string;
  medicalNotes: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const submitButtonChild = ref<HTMLElement | null>(null);

    const uploadPercentage = ref(0);
    const uploadPercentageChild = ref(0);
    const needValidation = ref({
      firstName: "",
      lastName: "",
      date: "",
    });
    const oldFirstName = ref("");
    const oldLastName = ref("");

    const profileDetailsValidator = Yup.object().shape({
      fnameF: Yup.string().required(t("editProfile.requiredFirstname")),
      fnameM: Yup.string().required(t("editProfile.requiredFirstname")),
      lnameF: Yup.string().required(t("editProfile.requiredLastname")),
      lnameM: Yup.string().required(t("editProfile.requiredLastname")),
      mPhone: Yup.string()
        .test("is-phone-number", t("editProfile.invalidPhone"), (value) => {
          if (value == undefined) return false;
          return value.length == 8;
        })
        .required(t("editProfile.requiredPhone")),
      fPhone: Yup.string()
        .test("is-phone-number", t("editProfile.invalidPhone"), (value) => {
          if (value == undefined) return false;
          return value.length == 8;
        })
        .required(t("editProfile.requiredPhone")),
    });
    const profileDetails = ref<ProfileDetails>({
      father: {
        firstName: "",
        lastName: "",
        phone: "",
        job: "",
      },
      mother: {
        firstName: "",
        lastName: "",
        phone: "",
        job: "",
      },
      adress: "",
      email: "",
      status: "",
    });
    const file = ref();
    const avatar = ref("media/avatars/blank.png");
    const avatarChild = ref("media/avatars/blank.png");
    const baseUrl = store.getters.serverConfigUrl.base_url + "/";
    ApiService.post("/parents/filter", {
      query: {
        _id: window.localStorage.getItem("parentId"),
      },
    })
      .then(({ data }) => {
        profileDetails.value = data[0];
        console.log("data[0]", data[0].photo);
        if (data[0].photo) {
          avatar.value = baseUrl + data[0].photo.replaceAll("\\", "/");
        }
      })
      .catch((e) => console.log(e));

    const saveChanges = () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        ApiService.patch(
          "/parents/" + window.localStorage.getItem("parentId"),
          {
            data: profileDetails.value,
          }
        )
          .then(({ data }) => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            event("Edit Profile info", {
              event_category: "Profile",
              event_label: "Profile section",
              value: 1,
            });
            Swal.fire({
              text: "Vos informations ont été sauvegardés .",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              hideModal(createAPIKeyModalRef.value);
            });
          })
          .catch((e) => console.log(e));
      }
    };
    const onChange = (event) => {
      var form = new FormData();
      form.append("photo", event.target.files[0]);
      ApiService.setFileHeader();
      ApiService.patch(
        "/parents/" + window.localStorage.getItem("parentId") + "/photo",
        form,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then((data) => {
          // event("Edit avatar", {
          //   event_category: "Profile",
          //   event_label: "Profile section",
          //   value: 1,
          // });
          avatar.value = baseUrl + data.data.imageURL.replaceAll("\\", "/");
          console.log("avatar after changes ", avatar);
          uploadPercentage.value = 0;
          Swal.fire({
            text: "Votre photo a été changée avec succès .",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            hideModal(createAPIKeyModalRef.value);
          });
        })
        .catch((e) => console.log(e));
    };

    const removeImage = () => {
      const avatar = "media/avatars/blank.png";
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [""]);
    });

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const childProfileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required(t("editProfile.requiredFirstname")),
      lname: Yup.string().required(t("editProfile.requiredLastname")),
      dataOfBirth: Yup.string().required(t("editProfile.requiredDateOfBirth")),
      phone: Yup.string().test(
        "is-phone-number",
        t("editProfile.invalidPhone"),
        (value) => {
          if (value == undefined) return false;
          if (value[0] && value[0] == "0") return false;
          return value.length == 8 || value.length == 0;
        }
      ),
    });

    const childDetails = ref<ChildDetails>({
      firstName: "",
      lastName: "",
      BirthDate: new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-"),
      adress: "",
      phone: "",
      medicalNotes: "",
    });

    const currentStudent = store.getters.currentStudent;

    ApiService.get("/students/" + currentStudent._id)
      .then(({ data }) => {
        if (data.photo && data.photo !== "")
          avatarChild.value = baseUrl + data.photo.replaceAll("\\", "/");
        childDetails.value.firstName = data.firstName;
        childDetails.value.lastName = data.lastName;
        childDetails.value.BirthDate = new Date(data.BirthDate)
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-");
        childDetails.value.adress = data.adress;
        childDetails.value.phone = data.phone;
        childDetails.value.medicalNotes = data.medicalNotes;
        if (data.needValidation) {
          needValidation.value.firstName = data.needValidation.firstName;
          needValidation.value.lastName = data.needValidation.lastName;
          needValidation.value.date = data.needValidation.date;
          if (data.needValidation.firstName) {
            childDetails.value.firstName = data.needValidation.firstName;
          }
          if (data.needValidation.lastName) {
            childDetails.value.lastName = data.needValidation.lastName;
          }
        }
        oldFirstName.value = data.firstName;
        oldLastName.value = data.lastName;
      })
      .catch((e) => console.log(e));

    const saveChangesChild = () => {
      if (submitButtonChild.value) {
        submitButtonChild.value.setAttribute("data-kt-indicator", "on");
        ApiService.patch("/lms/student/" + currentStudent._id, {
          data: {
            ...childDetails.value,
            BirthDate: new Date(childDetails.value.BirthDate),
          },
        })
          .then(({ data }) => {
            submitButtonChild.value?.removeAttribute("data-kt-indicator");
            event("Edit Profile info", {
              event_category: "Profile",
              event_label: "Profile section",
              value: 1,
            });
            Swal.fire({
              text: "Vos informations ont été sauvegardés .",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
            needValidation.value.firstName = data.student.needValidation
              ? data.student.needValidation.firstName
              : "";
            needValidation.value.lastName = data.student.needValidation
              ? data.student.needValidation.lastName
              : "";
            needValidation.value.date = data.student.needValidation
              ? data.student.needValidation.date
              : "";
          })
          .catch((e) => console.log(e));
      }
    };

    const onChangeChild = (event) => {
      const form = new FormData();
      form.append("photo", event.target.files[0]);
      ApiService.setFileHeader();
      ApiService.patch("/lms/student/" + currentStudent._id + "/photo", form, {
        onUploadProgress: function (progressEvent) {
          uploadPercentageChild.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      } as any)
        .then((data) => {
          // event("Edit avatar", {
          //   event_category: "Profile",
          //   event_label: "Profile section",
          //   value: 1,
          // });
          avatarChild.value =
            baseUrl + data.data.imageURL.replaceAll("\\", "/");
          console.log("avatar after changes ", avatarChild);
          uploadPercentageChild.value = 0;
          Swal.fire({
            text: "Votre photo a été changée avec succès .",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        })
        .catch((e) => console.log(e));
    };

    const removeImageChild = () => {
      avatarChild.value = "media/avatars/blank.png";
    };

    return {
      onChange,
      file,
      avatar,
      avatarChild,
      t,
      submitButton,
      saveChanges,
      profileDetails,
      removeImage,
      createAPIKeyModalRef,
      profileDetailsValidator,
      uploadPercentage,
      uploadPercentageChild,
      customColorMethod,
      childProfileDetailsValidator,
      childDetails,
      submitButtonChild,
      saveChangesChild,
      onChangeChild,
      removeImageChild,
      needValidation,
      oldFirstName,
      oldLastName,
    };
  },
});
