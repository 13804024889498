import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    readAndAccept: "I have read and accept the",
    termsAndConditions: "Terms and Conditions",
    calendar: {
      month: "Month",
      week: "Week",
      day: "Day",
      addEvent: "Add event",
      addEventTitle: "Add a new event",
      duration: "Duration",
      durationRequired: "Duration is required field",
      exam: "Exam",
      party: "Party",
      excursion: "Excursion",
      meeting: "Meeting",
      other: "Other",
      eventType: "Event type",
      color: "Color",
      typeRequired: "Event type is required field",
      editEventTitle: "Edit event",
      editEvent: "Edit event",
      editedEvent: "The event has been edited successfully",
      deletedEvent: "The event has been deleted successfully",
      addedEvent: "The event has been added successfully",
      tous: "All",
      teachers: "Teachers",
      students: "Students",
      administration: "Administration",
      parents: "Parents",
      title: "Title",
      target: "Targets",
      startDate: "Start date",
      endDate: "End date",
    },
    selectTeacher: "Select teacher",
    selectStudent: "Select student",
    uploadFiles: "Upload files",
    selectSubmissionMethod: "Select submission method",
    writeAnswer: "Write an answer",
    homeworkSubmitted: "Your homework has been submitted!",
    answer: "Answer",
    submit: "Submit",
    invalidSubmission: "Something went wrong",
    invalidSubmissionText: "You must submit files or write an answer",
    noCourses: "No courses have been published yet",
    noExercises: "No exercises have been published yet",
    noCompletedExercises: "No completed exercises yet",
    noMissingExercises: "No exercises are missing",
    details: "Details",
    pleaseWait: "Please Wait...",
    search: "Search",
    assign: "Assign",
    completed: "Completed",
    missing: "Missing",
    todo: "Todo",
    marked: "Marked",
    notMarked: "Not marked",
    subjects: "Subjects",
    subject: "Subject",
    courses: "Courses",
    course: "Course",
    exercises: "Exercises",
    exercise: "Exercise",
    nbrCourses: "There are {nb} courses",
    nbrExercises: "There are {nb} exercises",
    title: "Title",
    lastComment: "Last comment by",
    teacher: "Teacher",
    publicationDate: "Publication date",
    deadline: "Deadline",
    module: "Module",
    description: "Description",
    privateMessage: "Private message",
    resources: "Resources",
    homework: "Homework",
    yes: "Yes",
    no: "No",
    drobFiles: "Drop files here",
    fileSize: "PDF/DOC files of size less than 5 MB",
    reply: "Reply",
    finishedExercises: "Number of homework finished this year",
    notFinishedExercises: "Number of homework not finished",
    submissionsState: "Submissions state",
    submissionsStateTitle: "Student submissions in this trimester",
    incompleted: "Not finished",
    lateFinish: "Late finished",
    completeInTime: "Completed in time",
    noData: "No data",
    submission: "Submission",
    supportChat: {
      typeMessage: "Type a message",
      seen: "Seen",
      send: "Send",
      online: "Online",
      confirmDeleteMessage: "Are you sure to delete this message?",
      deletedMessage: "This message was deleted",
      you: "You",
    },
    chat: {
      ROOMS_EMPTY: "No rooms",
      ROOM_EMPTY: "No room selected",
      NEW_MESSAGES: "New Messages",
      MESSAGE_DELETED: "This message was deleted",
      MESSAGES_EMPTY: "No messages",
      CONVERSATION_STARTED: "Conversation started on:",
      TYPE_MESSAGE: "Type message",
      SEARCH: "Search",
      IS_ONLINE: "is online",
      LAST_SEEN: "last seen ",
      startNewConversationWithTeacher: "Create new conversation with teacher",
      addTeacher: "Add teacher",
      chatWithTeachers: "Chat with teachers & administration",
    },
    notification: {
      notifications: "Notifications",
      newNotification: "New notification",
      viewAll: "View all",
    },
    paymentStatus: {
      paid: "Paid",
      unpaid: "Unpaid",
      paymentStatus: "Payment status",
      paidAmount: "The amount paid",
      monthRemainingPayments: "{month} remaining payments",
      allRemainingPayments: "All remaining payments",
      noRemainingPyaments: "There are no remaining payments",
      nothingPaid: "There are no payments done",
      tnd: "TND",
    },
    login: {
      title: "Parent's Area",
      emailInfo:
        "Use the email and password you received from the administration.",
      emailOrUsername: "Email or Username",
      requiredEmailOrUsername: "Username/Email is a required field",
      password: "Password",
      requiredPassword: "Password is a required field",
      passwordCondition: "Password must be at least 4 characters",
      yourSchool: "Your child's school",
      chooseSchool: "Please choose the school",
      submit: "Continue",
      success: "You have successfully logged in!",
      getIt: "Ok, got it!",
      incorrectInfo: "The Username/Password is incorrect",
      tryAgain: "Try again!",
      loginForgotPassword: "Forgot your password?",

      requiredEmail: "The email is a required field",
      invalidEmail: "The email must be a valid email address",
      schoolNotRegistered:
        "The school is not registered on the EdTrust platform!",
    },
    attendance: {
      attendanceState: "Attendance State",
      absent: "Absent",
      delay: "Delay",
      present: "Present",
      absents: "Absents",
      delays: "Delays",
      inThisYear: "In this year",
      totalAbsents: "Number of absences in this year",
      totalDelays: "Number of delays in this year",
      startDate: "Start date",
      endDate: "End date",
    },
    resetpassword: {
      linkExpired: "The link you visited is expired",
      linkExpiredDesc: "The link you visited is expired. Please try again.",
      passwordChanged: "Your password has been changed successfully",
      close: "Close",
      forgotPassword: "Forgot Password",
      forgotPasswordText:
        "Enter your email to reset your password. If you do not have an email, please contact the administrator",
      resetPasswordReqested: "Password reset requested",
      resetPasswordReqestedDesc:
        "We have sent you an email, please check it to reset your password !",
      cancel: "Cancel",
      submit: "Submit",
      emailNotExist: "This email does not exist",
    },
    feedback: {
      send: "Send",
      feedback: "Feedback",
      sendFeedback: "Send Feedback",
      paragraphe:
        "We appreciate your feedback on our app. Please share your thoughts with us.",
      placeholder: "Enter something ...",
      category: "Category",
      comment: "Comment",
      delete: "Delete",
      problem: "Problem/Bug",
      idea: "Idea For Improvement",
      others: "Others",
      section: "Section",
      selectSection: "Please select the section",
      priority: "Priority",
      selectPriority: "Please choose the priority",
      low: "Low",
      medium: "Medium",
      high: "High",
      critical: "Critical",
      message: "Message",
      uploadImages: "Upload images",
      uploadAudio: "Upload audio",
      record: "Record",
      successSent:
        "Thank you for your contribution. We'll review it and get back to you if needed. Have a great day!",
    },
    timetable: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      lesson: "lesson",
      skip: "skip",
      cabinet: "Room",
      court: "court",
      break: "Break",
      sickcall: "Sick call for today",
      print: "Print",
    },
    editProfile: {
      fname: "First Name",
      lname: "Last Name",
      birthDay: "Birth Day",
      medicalNote: "Medical Note",
      requiredDateOfBirth: "Date Of Birth is a required field",
      photo: "Profile Picture",
      father: "Father Full Name ",
      mother: "Mother Full Name",
      motherPhone: "Father Contact Phone ",
      fatherPhone: "Mother Contact Phone",
      save: "Save Changes",
      discard: "Discard",
      wait: "Please wait... ",
      edit: "Edit Profile",
      editChild: "Edit Child Profile",
      allowedFiles: "Allowed file types: png, jpg, jpeg.",
      motherJob: " Mother Job",
      fatherJob: "Father Job ",
      adress: "Address",
      invalidEmail: "The email must be a valid email address",
      requiredEmail: "The email is a required field",
      requiredSchool: "The school name is a required field",
      confirmPassword: "Confirm the password",
      password: "Password",
      resetPassword: "Reset password",
      passwordRule:
        "Password must be at least 8 characters long and contain symbols",
      equiredFirstname: "First name is a required field",
      requiredLastname: "Last name is a required field",
      requiredPhone: "Phone number is a required field",
      requiredAddress: "Address is a required field",
      invalidPhone: "The phone number must contain 8 digits",

      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm the new password",
      updatePassword: "Update password",
      requiredCurrentPassword: "Current password is a required field",
      requiredNewPassword: "New Password is a required field",
      requiredConfirmPassword: "Password Confirmation is a required field",
      passwordConfirmationCondition:
        "Password Confirmation must be at least 4 characters",
      passwordNewCondition: "New Password must be at least 4 characters",
      passwordMustMatch: "Password must match",
      passwordUpdated: "Your password has been updated successfully",
      requestedNameChanges: "Your requested name changes are being reviewed by an administrator",
      changesRequested: "Changes requested on",
    },
    dashboard: {
      dashboard: "Dashboard",
      phoneNumber: "Phone number",
      headMaster: "Headmaster",
      adress: "Address",
      building: "School",
      settings: "Settings",
      consultpayment: "My Payment",
      mychild: "My Child",
      actualites: "News",
      comments: "Comments",
      likes: "Likes",
      details: "Details",
      canteen: "Canteen",
      payment: "Payment",
      report: "School Report",
      reportPeda: "Pedagogic School Report",
      reportSpec: "Pilote School Report",
      reportVerySpec: "Specific School Report",
      reportPedaAnnuelle: "Annual pedagogic report",
      reportSpecAnnuelle: "Annual pilote report",
      reportVerySpecAnnuelle: "Annual specific report",
      trimestrielle: "Trimestrial",
      annuelle: "Annual",
      bulletinAnnuelle: "Annual Report",
      schedule: "Schedule",
      avertissements: "Sanctions",
      attendance: "Attendance",
      homeworks: "Homeworks",
      courses: "Courses",
      profile: "My Profile",
      language: "Language",
      signout: "Sign Out",
      arabic: "Arabic",
      english: "English",
      french: "French",
      studentSpace: "Student Space",
    },
    report: {
      informations:
        "The administration will add the transcripts at the end of each trimester so that you can monitor your child's level.",
      update: "Last update :",
      premsem: "1st trimester",
      deuxsem: "2nd trimester",
      troisem: "3rd trimester",
      download: "Download",
      unavailable: "The school report is unavailable right now !",
      yourStudentMark: "Your child's\n mark",
      maxMoyenne: "Highest average",
      minMoyenne: "Lowest average",
      maxMark: "Highest mark",
      minMark: "Lowest mark",

      moyenneTrimestre: "Trimester average : {moyenne}",
      moyenneMax: "Highest average : {moyenne}",
      moyenneMin: "Lowest average : {moyenne}",
      performancesNotes: "Grades performance",

      classroomName: "Classroom Name",
      nbrStudents: "Number of student",

      studentBulletinFinal: "Student name",
      moyenneTrimester1: "Trimester 1 average",
      moyenneTrimester2: "Trimester 2 average",
      moyenneTrimester3: "Trimester 3 average",
      moyenneGeneral: "General average",
      bulletinFinalResult: "Council decision",
    },
    canteen: {
      informations:
        "The canteen is often the lunchtime solution. A new adventure for your children who, for the most part, want more after tasting it! We don’t laugh with children's food! The school offers them balanced and varied menus and, even if it cannot ensure their nutritional balance on its own, the midday meals have the merit, in any case, of meeting their needs. The administration will add the dishes of each day.",
      informations2: "The administration will add the dishes of each day.",
      choosedate: "Choose a date :",
      unavailable: "Unavailable Data !",
      forthisdate: "For this date :",
      schooloffers: "Our school offers ",
    },
    schedule: {
      informations:
        "The administration will add your child's schedule for each semester.",
      unavailable: "Unavailable schedule !",
    },
    avertissement: {
      teacher: "Teacher",
      subject: "Subject",
      reason: "Reason",
      notes: "Notes",
      importantInformations: "Important informations",
      informations:
        "The sanctions contribute not to exclude from the class but to integrate into it: they recognize the pupil's responsibility for his acts and, at the same time, allow him to return to the group from which he has excluded himself. .Any warning is validated by our administration. ",
      informations2: "For more information, please contact the administration.",
      totalnumber: "Total Number",
      unavailable: "There are no sanctions !",
    },
    news: {
      reaction: "Your Reactions",
      totalPosts: "Number of Posts",
      newPosts: "New Posts",
      noNews: "The administration has not published any news yet",
    },
    payment: {
      monthly: "Monthly",
      scolarityPayment: "Scolarity Payments",
      activityPayment: "Activity Payments",
      finalStatus: "Final Status",
      month: "Month",
      state: "State",
      activityName: "Activity Name",
      fees: "Fees",
      progress: "Progress of payment",
      singlePayment: "Single Payment",
      monthlyPayment: "Monthly Payment",
      monthlyFees: "Monthly Fees",
      payThisMonth: "Remaining to pay this month",
      totalPaid: "Total Payments",
      afterReduction: "After reduction",
      inscriptionFees: "Registration fees",
      inscriptionReduction: "Registration reduction",
      monthlyReduction: "Monthly reduction",
      inscription: "Registration",
      print: "Print",
    },
    settings: {
      options: "Options",
      child: "Child",
      year: "School Year",
      apply: "Apply",
      reset: "Reset",
      chooseChild: "Choose your child",
      chooseYear: "Choose the school year",
      trimester: "Choose the trimester",
    },
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    onlinePayment: {
      success: "Payment Successful!",
      thanksMsg: "Thank you for your purchase, you will receive your access via SMS.",
      successMsg: "Your payment was processed successfully.",
      goBackBtn: "Return to Kutubee",
      failure: "Payment Failed",
      failureMsg: "We are sorry, your payment was not processed.",
      failureRetry: "There was a problem with your payment. Please try again.",
      retryPaiment: "Retry Payment",
      orderId: "Order ID :"

    }

  },

  fr: {
    readAndAccept: "j'ai lu et j'accepte les",
    termsAndConditions: "Termes et Conditions",
    calendar: {
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      addEvent: "Ajouter un événement",
      addEventTitle: "Ajouter un nouvel événement",
      duration: "Durée",
      durationRequired: "La durée est requise",
      exam: "Examen",
      party: "Fête",
      excursion: "Excursion",
      meeting: "Réunion",
      other: "Autre",
      eventType: "Type d'événement",
      color: "Couleur",
      typeRequired: "Le type d'événement est requis",
      editEventTitle: "Modifier l'événement",
      editEvent: "Modifier l'événement",
      editedEvent: "L'événement a été modifié avec succès",
      deletedEvent: "L'événement a été supprimé avec succès",
      addedEvent: "L'événement a été ajouté avec succès",
      tous: "Tous",
      teachers: "Enseignants",
      students: "Elèves",
      administration: "Administration",
      parents: "Parents",
      title: "Titre",
      target: "Cibles",
      startDate: "Date de début",
      endDate: "Date de fin",
    },
    selectTeacher: "Sélectionnez l'enseignant",
    selectStudent: "Sélectionnez l'enfant",
    uploadFiles: "Choisir des fichiers",
    selectSubmissionMethod: "Sélectionnez la méthode",
    writeAnswer: "Rédigez une réponse",
    homeworkSubmitted: "Vos devoirs ont été remis",
    answer: "Réponse",
    submit: "Envoyer",
    invalidSubmission: "Quelque chose s'est mal passé!",
    invalidSubmissionText:
      "Vous devez soumettre des fichiers ou écrire la réponse!",
    noCourses: "Aucun cours n'a encore été publié",
    noExercises: "Aucun exercice n'a encore été publié",
    noCompletedExercises: "Il n'y a pas encore d'exercices terminés",
    noMissingExercises: "Aucun exercice manquant",
    details: "Détails",
    pleaseWait: "S'il vous plaît, attendez...",
    search: "Recherche",
    marked: "Noté",
    notMarked: "Non noté",
    assign: "Attribué",
    completed: "Terminé",
    missing: "Manquant",
    todo: "À Faire",
    subjects: "Matières",
    subject: "Matière",
    courses: "Cours",
    course: "Cours",
    exercises: "Exercices",
    exercise: "Exercice",
    nbrCourses: "Il y a {nb} cours",
    nbrExercises: "Il y a {nb} exercices",
    title: "Titre",
    lastComment: "Dernier commentaire par",
    teacher: "Enseignant",
    publicationDate: "Date de publication",
    deadline: "Date limite",
    module: "Module",
    description: "Description",
    privateMessage: "Message privé",
    homework: "Devoir maison",
    yes: "Oui",
    no: "Non",
    drobFiles: "Déposez les fichiers ici",
    fileSize: "Fichiers PDF/DOC d'une taille inférieure à 5 Mo",
    reply: "Réponse",
    finishedExercises: "Nombre de devoirs terminés cette année",
    notFinishedExercises: "Nombre de devoirs non terminés",
    submissionsState: "État des soumissions",
    submissionsStateTitle: "Soumissions des étudiants au cours de ce trimestre",
    incompleted: "Manquants",
    lateFinish: "Terminé en retard",
    completeInTime: "Terminé à temps",
    presence: "Présence",
    illnesses: "Maladies",
    delays: "Retard",
    absences: "Absences",
    supportChat: {
      typeMessage: "Tapez un message",
      seen: "Vu",
      send: "Envoyer",
      online: "En ligne",
      confirmDeleteMessage: "Voulez-vous vraiment supprimer ce message ?",
      deletedMessage: "Ce message a été supprimé",
      you: "Toi",
    },
    chat: {
      ROOMS_EMPTY: "Aucune conversation",
      ROOM_EMPTY: "Aucune conversation sélectionnée",
      NEW_MESSAGES: "Nouveaux messages",
      MESSAGE_DELETED: "Ce message a été supprimé",
      MESSAGES_EMPTY: "Aucun message",
      CONVERSATION_STARTED: "La conversation a commencée le :",
      TYPE_MESSAGE: "Tapez votre message",
      SEARCH: "Rechercher",
      IS_ONLINE: "est en ligne",

      parentDetails: "Détails du parent",
      startNewConversationWithParent:
        "Créer nouvelle conversation avec un parent",
      addParent: "Ajouter un parent",
      addTeacher: "Ajouter un teacher",
      chatWithTeachers: "Discuter avec les enseignants & administration",
      startNewConversationWithTeacher:
        "Créer nouvelle conversation avec un enseignant",
    },
    notification: {
      notifications: "Notifications",
      newNotification: "Nouvelle notification",
      viewAll: "Voir tout",
    },
    noData: "Pas d'information disponible",
    submission: "Soumission",
    paymentStatus: {
      paid: "Payé",
      unpaid: "Non Payé",
      paymentStatus: "Statut du paiement",
      paidAmount: "Le montant payé",
      monthRemainingPayments: "Paiements restants ({month})",
      allRemainingPayments: "Tous les paiements restants",
      noRemainingPyaments: "Pas de paiements restants",
      nothingPaid: "Pas de paiements payés",
      tnd: "DT",
    },
    login: {
      title: "Espace Parent",
      emailInfo:
        "Utilisez l'email et le mot de passe que vous avez reçu par l'administration .",
      emailOrUsername: "Email ou Nom d'utilisateur",
      requiredEmailOrUsername:
        "Le nom d'utilisateur/l'email est un champ obligatoire",
      password: "Mot de passe",
      requiredPassword: "Mot de passe est un champ obligatoire",
      passwordCondition: "Le mot de passe doit comporter au moins 4 caractères",
      yourSchool: "L'école de votre enfant",
      chooseSchool: "Veuillez choisir l'école",
      submit: "Continuer",
      success: "Vous êtes connecté(e) .",
      incorrectInfo:
        "Vous avez entré un nom d'utilisateur/un mot de passe invalide !",
      tryAgain: "Réessayez !",
      loginForgotPassword: "Mot de passe oublié ?",

      requiredEmail: "L'email est un champ obligatoire",
      invalidEmail: "L'e-mail doit être valide",
      schoolNotRegistered:
        "L'école n'est pas enregistrée sur la plate-forme EdTrust !",
    },
    attendance: {
      attendanceState: "État de présence",
      absent: "Absent",
      delay: "Retard",
      absents: "Absents",
      delays: "Retards",
      present: "Présent",
      inThisYear: "Cette année",
      totalAbsents: "Nombre d'absences cette année",
      totalDelays: "Nombre de retards cette année",
      startDate: "Date de début",
      endDate: "Date de fin",
    },
    resetpassword: {
      linkExpired: "Le lien que vous avez suivi a expiré",
      linkExpiredDesc:
        "Le lien que vous avez suivi a expiré. Veuillez réessayez.",
      passwordChanged: "Votre mot de passe a été changé avec succès",
      close: "Fermer",
      forgotPassword: "Mot de passe oublié",
      forgotPasswordText:
        "Entrez votre email pour réinitialiser votre mot de passe. Si vous n'avez pas d'email, veuillez contacter l'administrateur",
      resetPasswordReqested: "Réinitialisation du mot de passe demandée",
      resetPasswordReqestedDesc:
        "Nous vous avons envoyé un email, veuillez le vérifier pour réinitialiser votre mot de passe !",
      cancel: "Annuler",
      submit: "Envoyer",
      emailNotExist: "Cette adresse email n'existe pas !",
    },
    feedback: {
      send: "Envoyer",
      feedback: "Avis",
      sendFeedback: "Envoyer un avis",
      paragraphe:
        "Votre avis est précieux pour nous ! Faites-nous part de vos commentaires sur notre application.",
      placeholder: "Ecrivez votre avis s'il vous plait ...",

      category: "Catégorie",
      comment: "Commentaire",
      delete: "Supprimer",
      problem: "Probléme/bug",
      idea: "Idée d'amélioration",
      others: "Autre",
      section: "Section",
      selectSection: "Veuillez sélectionner la section",
      priority: "Priorité",
      selectPriority: "Veuillez choisir la priorité",
      low: "Basse",
      medium: "Moyenne",
      high: "Haute",
      critical: "Critique",
      message: "Message",
      uploadImages: "Importer des images",
      uploadAudio: "Enregistrement audio",
      record: "Enregistrer",

      successSent:
        "Merci pour votre contribution. Nous allons l'examiner et vous recontacter si besoin. Bonne journée !",
    },
    timetable: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
      lesson: "leçon",
      skip: "Passer",
      cabinet: "Salle",
      court: "cours",
      break: "Pause",
      sickcall: "Appel malade pour aujourd'hui",
      print: "Imprimer",
    },
    news: {
      reaction: "Vos réactions",
      totalPosts: "Total des publications",
      newPosts: "Nouvelles Publications",
      noNews: "L'administration n'a publié aucune actualité",
    },
    editProfile: {
      fname: "Prénom",
      lname: "nom",
      birthDay: "Date de naissance",
      medicalNote: "Note médicale",
      requiredDateOfBirth: "La date de naissance est un champ obligatoire",
      photo: "Photo",
      father: "Nom et prénom du père",
      mother: "Nom et prénom de la mère",
      motherPhone: "Numéro du téléphone",
      fatherPhone: "Numéro du téléphone",
      save: "Sauvegarder les changements",
      discard: "Annuler",
      wait: "Attendez s'il vous plait ...",
      edit: "Changer les informations personnelles",
      editChild: "Modifier le profil de l’enfant",
      allowedFiles: "Les types de fichier autorisés: png, jpg, jpeg.",
      motherJob: " Profession de la mère",
      fatherJob: "Profession du père ",
      adress: "Adresse",
      invalidEmail: "L'email doit être valide",
      requiredEmail: "L'email est un champ obligatoire",
      requiredSchool: "Le nom de l'école est un champ obligatoire",
      confirmPassword: "Confirmez le mot de passe",
      password: "Mot de passe",
      resetPassword: "Réinitialiser le mot de passe",
      passwordRule:
        "Le mot de passe doit comporter au moins 8 caractères et contenir des symboles",
      requiredFirstname: "Prénom est un champ obligatoire",
      requiredLastname: "Nom est un champ obligatoire",
      requiredPhone: "Télephone est un champ obligatoire",
      requiredAddress: "Adresse est un champ obligatoire",
      invalidPhone: "Le numéro de téléphone doit contenir 8 chiffres",

      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmez le nouveau mot de passe",
      updatePassword: "Mettre à jour le mot de passe",
      requiredCurrentPassword:
        "Le mot de passe actuel est un champ obligatoire",
      requiredNewPassword: "Le nouveau mot de passe est un champ obligatoire",
      requiredConfirmPassword:
        "La confirmation du mot de passe est un champ obligatoire",
      passwordConfirmationCondition:
        "La confirmation du mot de passe doit contenir au moins 4 caractères",
      passwordNewCondition:
        "Le nouveau mot de passe doit comporter au moins 4 caractères",
      passwordMustMatch: "Les mot de passes doivent être identiques",
      passwordUpdated: "Votre mot de passe a été mis à jour avec succès",
      requestedNameChanges: "Votre demande de changement de nom est en cours d'examen par un administrateur",
      changesRequested: "Changements demandés le",
    },
    dashboard: {
      dashboard: "Accueil",
      phoneNumber: "Numéro du téléphone",
      headMaster: "Directeur",
      adress: "Adresse",
      building: "École",
      settings: "Paramètres",
      consultpayment: "Consulter Mes Paiements",
      mychild: "Mon enfant",
      details: "Détails",
      actualites: "Actualités",
      comments: "Commentaires",
      likes: "Mentions de j'aime",
      canteen: "Cantine",
      payment: "Paiement",
      report: "Bulletin",
      reportPeda: "Bulletin Pédagogique",
      reportSpec: "Bulletin Pilote",
      reportVerySpec: "Bulletin Spécifique",
      reportPedaAnnuelle: "Bulletin pédagogique annuel",
      reportSpecAnnuelle: "Bulletin pilote annuel",
      reportVerySpecAnnuelle: "Bulletin spécifique annuel",
      trimestrielle: "Trimestrielle",
      annuelle: "Annuelle",
      bulletinAnnuelle: "Bulletin Annuel",
      avertissements: "Sanctions",
      attendance: "Assiduité",
      schedule: "Emploi",
      homeworks: "Exercices",
      courses: "Cours",
      profile: "Mon Profil",
      language: "Langue",
      signout: "Déconnexion",
      arabic: "Arabe",
      english: "Anglais",
      french: "Français",
      studentSpace: "Espace éléve",
    },
    report: {
      informations:
        "L'administration ajoutera les bulletins de notes à la fin de chaque trimestre pour que vous pouvez suivre l'évolution de votre enfant.",
      update: "Dernier mise à jour : ",
      premsem: "1er trimestre",
      deuxsem: "2ème trimestre",
      troisem: "3ème trimestre",
      download: "Télécharger",
      unavailable: "Le bulletin est indisponible pour le moment !",
      yourStudentMark: "Note de votre\n enfant",
      maxMoyenne: "Moyenne la plus haute",
      minMoyenne: "Moyenne la plus basse",
      maxMark: "Note la plus\n haute",
      minMark: "Note la plus\n basse",

      moyenneTrimestre: "Moyenne du trimestre : {moyenne}",
      moyenneMax: "Moyenne la plus haute : {moyenne}",
      moyenneMin: "Moyenne la plus basse : {moyenne}",
      performancesNotes: "Performances des notes",

      classroomName: "Nom de la classe",
      nbrStudents: "Nombre d'étudiants",

      studentBulletinFinal: "Nom de l'élève",
      moyenneTrimester1: "Moyenne du 1er trimestre",
      moyenneTrimester2: "Moyenne du 2ème trimestre",
      moyenneTrimester3: "Moyenne du 3ème trimestre",
      moyenneGeneral: "Moyenne générale",
      bulletinFinalResult: "Décision du conseil",
    },
    canteen: {
      informations:
        "La cantine, c’est souvent la solution du midi. Une nouvelle aventure pour vos enfants qui, pour la plupart, en redemandent après y avoir goûté ! On ne rigole pas avec l’alimentation des enfants ! L’école leur propose des menus équilibrés et variés et, même si elle ne peut pas assurer à elle seule leur équilibre alimentaire, les repas du midi ont le mérite, en tout cas, de répondre à leurs besoins.",
      informations2: "L'administration ajoutera les plats de chaque jour .",
      choosedate: "Choisissez une date :",
      unavailable: "Données indisponibles !",
      forthisdate: "Pour cette date :",
      schooloffers: "Notre école propose",
    },
    schedule: {
      informations:
        "L'administration ajoutera l'emploi de votre enfant pour chaque trimestre .    ",
      unavailable: "Emploi indisponible !",
    },

    avertissement: {
      teacher: "Professeur",
      subject: "Matière",
      reason: "Raison",
      totalnumber: "Nombre Total",
      notes: "Notes",
      importantInformations: "Informations importantes !",
      informations:
        "Les sanctions contribuent non à exclure de la classe mais à y intégrer : elles reconnaissent à l'élève la responsabilité de ses actes et, en même temps, lui permettent de revenir dans le collectif dont il s'est lui-même exclu.Tout avertissement est validé par notre administration.",
      informations2:
        "Pour plus d'information , veuillez contacter l'administartion.",
      unavailable: "Aucun avertissement!",
    },
    payment: {
      scolarityPayment: "Paiement Scolarité",
      activityPayment: "Paiement Activités",
      finalStatus: "Statut Final",
      month: "Mois",
      state: "Etat",
      activityName: "Nom d'activité",
      fees: "Frais",
      progress: "Progrès du paiement",
      singlePayment: "Paiement unique",
      monthlyPayment: "Paiement mensuel",
      monthlyFees: "Frais mensuels",
      payThisMonth: "À payer ce mois-ci",
      totalPaid: "Total a payé",
      afterReduction: "Après réduction",
      inscriptionFees: "Frais d'inscription",
      inscriptionReduction: "Réduction d'inscription",
      monthlyReduction: "Réduction mensuelle",
      monthly: "Mensuel",
      inscription: "Inscription",
      print: "Imprimer",
    },
    settings: {
      options: "Options",
      child: "Enfant",
      year: "Année Scolaire",
      apply: "Appliquer",
      reset: "Annuler",
      chooseChild: "Choisir parmi vos enfants",
      chooseYear: "Choisir l'année scolaire",
      trimester: "Choisir le trimestre",
    },
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    onlinePayment: {
      success: "Paiement Réussi !",
      thanksMsg: "Merci pour votre achat, vous allez recevoir votre acceés par sms.",
      successMsg: "Votre paiement a été traité avec succès.",
      goBackBtn: "Retour à Kutubee",
      failure: "Paiement Échoué",
      failureMsg: "Nous sommes désolés, votre paiement n'a pas été traité.",
      failureRetry: "Il y a eu un problème avec votre paiement. Veuillez réessayer.",
      retryPaiment: "Réessayer le Paiement",
      orderId: "Numéro de commande :"
    }
  },
  ar: {
    readAndAccept: "قرأت وأوافق على",
    termsAndConditions: "الشروط والأحكام",
    calendar: {
      month: "شهر",
      week: "أسبوع",
      day: "يوم",
      addEvent: "إضافة حدث",
      addEventTitle: "إضافة حدث جديد",
      duration: "المدة",
      durationRequired: "المدة مطلوبة",
      exam: "إمتحان",
      party: "حفلة",
      excursion: "رحلة",
      meeting: "إجتماع",
      other: "أخرى",
      eventType: "نوع الحدث",
      color: "اللون",
      typeRequired: "نوع الحدث مطلوب",
      editEventTitle: "تعديل الحدث",
      editEvent: "تعديل الحدث",
      editedEvent: "تم تعديل الحدث بنجاح",
      deletedEvent: "تم حذف الحدث بنجاح",
      addedEvent: "تم إضافة الحدث بنجاح",
      tous: "الكل",
      teachers: "المعلمون",
      students: "الطلاب",
      administration: "الادارة",
      parents: "الآباء",
      title: "العنوان",
      target: "هدف",
      startDate: "تاريخ البداية",
      endDate: "تاريخ النهاية",
    },
    selectTeacher: "اختر المعلم",
    selectStudent: "اختر التلميذ",
    supportChat: {
      typeMessage: "اكتب رسالة",
      seen: "تم العرض",
      send: "إرسال",
      online: "متصل",
      confirmDeleteMessage: "هل أنت متأكد من حذف هذه الرسالة؟",
      deletedMessage: "تم حذف هذه الرسالة",
      you: "أنت",
    },
    chat: {
      ROOMS_EMPTY: "لا توجد غرف",
      ROOM_EMPTY: "لم يتم اختيار غرفة",
      NEW_MESSAGES: "رسائل جديدة",
      MESSAGE_DELETED: "تم حذف هذه الرسالة",
      MESSAGES_EMPTY: "لا توجد رسائل",
      CONVERSATION_STARTED: "بدأت المحادثة في : ",
      TYPE_MESSAGE: "اكتب الرسالة",
      SEARCH: "بحث",
      IS_ONLINE: "متصل",
      LAST_SEEN: "اخر ظهور ",

      parentDetails: "معلومات الوالدين",
      startNewConversationWithParent: "إنشاء محادثة جديدة مع أحد الأولياء",
      addParent: "إضافة الولي",
      addTeacher: "إضافة مدرس",
      chatWithTeachers: " محادثة مع المعلمين أو الإدارة",
      startNewConversationWithTeacher: "إنشاء محادثة جديدة مع معلم",
    },
    notification: {
      notifications: "الإشعارات",
      newNotification: "إشعار جديد",
      viewAll: "عرض الكل",
    },
    uploadFiles: "رفع ملفات",
    selectSubmissionMethod: "إختر طريقة التسليم",
    writeAnswer: "كتابة إجابة",
    homeworkSubmitted: "تم تسليم واجبك بنجاح",
    answer: "إجابة",
    submit: "إجابة",
    invalidSubmission: "حدث خطأ ما",
    invalidSubmissionText: "يجب ان تقوم بتسليم ملفات او ان تقوم بكتابة الإجابة",
    noCourses: "لم يتم نشر أي دروس حتى الآن",
    noExercises: "لم يتم نشر أي تمارين حتى الآن",
    noCompletedExercises: "لا توجد تمارين مكتملة حتى الآن",
    noMissingExercises: "لا توجد تمارين غير منجزة",
    details: "التفاصيل",
    pleaseWait: "... يرجى الإنتظار قليلاً",
    todo: "الواجبات المنزلية",
    search: "بحث",
    marked: "بالنقاط",
    notMarked: "بدون نقاط",
    assign: "تعيين",
    completed: "مكتمل",
    missing: "غير مكتمل",
    subjects: "المواد",
    subject: "المادة",
    courses: "الدروس",
    course: "الدرس",
    exercises: "التمارين",
    exercise: "التمرين",
    nbrCourses: "درس {nb}  يوجد",
    nbrExercises: "تمرين {nb} يوجد",
    title: "العنوان",
    lastComment: "آخر تعليق",
    teacher: "الأستاذ",
    publicationDate: "تاريخ النشر",
    deadline: "الموعد النهائي",
    module: "الوحدة",
    description: "الوصف",
    privateMessage: "رسالة خاصة",
    resources: "المصادر",
    homework: "الواجبات المنزلية",
    yes: "نعم",
    no: "لا",
    drobFiles: "اسحب الملفات هنا",
    fileSize: "ملفات pdf / doc بحجم أقل من 5 ميجا بايت",
    reply: "رد",
    finishedExercises: "عدد الواجبات المنزلية المنتهية هذا العام",
    notFinishedExercises: "عدد الواجبات المنزلية غير منتهية",
    submissionsState: "احصائيات التقديمات",
    submissionsStateTitle: "تقديمات هذا الثلاثي الخاصة بالتلميذ",
    incompleted: "غير مكتمل",
    lateFinish: "مكتمل مع التأخر",
    completeInTime: "مكتمل في الوقت",
    noData: "لا يوجد بيانات",
    submission: "الواجب",
    paymentStatus: {
      paid: "مدفوع",
      unpaid: "غير مدفوع",
      paymentStatus: "حالة الدفوعات",
      paidAmount: "المبالغ المدفوعة",
      monthRemainingPayments: " المبالغ المتبقيّة لشهر {month}",
      allRemainingPayments: "كل المبالغ المتبقيّة",
      noRemainingPyaments: "لا يوجد مبالغ متبقيّة",
      nothingPaid: "لا يوجد أي مفوعات",
      tnd: "دينار",
    },
    login: {
      title: "فضاء الأولياء",
      emailInfo:
        "استخدم البريد الإلكتروني وكلمة المرور اللذين تلقيتهما من الإدارة.",
      emailOrUsername: "اسم المستخدم أو البريد الإلكتروني",
      requiredEmailOrUsername: "اسم المستخدم او البريد الإلكتروني مطلوب",
      password: "كلمة المرور",
      requiredPassword: "كلمة المرور مطلوبة",
      passwordCondition: "يجب أن تتكون كلمة المرور من 4 حروف على ألافل",
      yourSchool: "مدرسة طفلك",
      chooseSchool: "إختر المدرسة",
      submit: "دخول",
      success: "لقد سجلت الدخول.",
      incorrectInfo: "لقد أدخلت اسم مستخدم أو كلمة مرور غير صحيحة!",
      tryAgain: "!أعد المحاولة",
      loginForgotPassword: "نسيت كلمة المرور؟",

      requiredEmail: "البريد الإلكتروني مطلوب",
      invalidEmail: "البريد الإلكتروني غير صحيح",
      schoolNotRegistered: "!Ed Trust المدرسة غير مسجلة في منصة ",
    },
    attendance: {
      attendanceState: "احصائيات الحضور",
      absent: "غائب",
      delay: "متأخر",
      present: "حاضر",
      absents: "غيابات",
      delays: "تأخرات",
      inThisYear: "في هذه السنة",
      totalAbsents: "عدد الغيابات هذا العام",
      totalDelays: "عدد التأخيرات في هذا العام",
      startDate: "تاريخ الانتهاء",
      endDate: "تاريخ البداية",
    },
    resetpassword: {
      linkExpired: "لقد انتهت صلاحية هذا الرابط بالفعل",
      linkExpiredDesc: "لقد انتهت صلاحية هذا الرابط، يرجى إعادة المحاولة",
      passwordChanged: "تم تغيير كلمة المرور الخاصة بك بنجاح",
      close: "إغلاق",
      forgotPassword: "نسيت كلمة المرور",
      forgotPasswordText:
        "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك. إذا لم يكن لديك بريد إلكتروني ، يرجى الاتصال بالإدارة",
      resetPasswordReqested: "طلب إعادة تعيين كلمة المرور",
      resetPasswordReqestedDesc:
        "لقد أرسلنا لك بريدًا إلكترونيًا ، يرجى التحقق منه لإعادة تعيين كلمة المرور الخاصة بك!",
      cancel: "إلغاء",
      submit: "تفعيل",
      emailNotExist: "هذا البريد الإلكتروني غير موجود",
    },
    feedback: {
      send: "ارسال",
      feedback: "تعليق",
      sendFeedback: "إرسال تعليق",
      paragraphe: "نحن نهتم برأيكم! يُرجى مشاركتنا تجربتكم مع تطبيقنا.",
      placeholder: "بامكانك كتابة رايك ",
      category: "فئة",
      comment: "تعليق",
      delete: "Supprimer",
      problem: "مشكلة / خطأ",
      idea: "فكرة للتحسين",
      others: "أخرى",
      section: "القسم",
      selectSection: "الرجاء تحديد القسم",
      priority: "أولوية",
      selectPriority: "الرجاء اختيار الأولوية",
      low: "قليلة",
      medium: "متوسطة",
      high: "عالية",
      critical: "شديدة الأهمية",
      message: "رسالة",
      uploadImages: "رفع صور",
      uploadAudio: "رفع مقطع صوتي",
      record: "تسجيل",

      successSent:
        "شكرًا لك على مساهمتك. سنراجعها ونرد عليك إذا لزم الأمر. نتمنى لك يومًا سعيدًا!",
    },
    timetable: {
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الاربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
      lesson: "درس",
      skip: "تخطي",
      cabinet: "القاعة",
      court: "الفصل",
      break: "راحة",
      sickcall: "إتصال من أجل المرض اليوم",
      print: "طباعة",
    },
    news: {
      reaction: "منشوراتك",
      totalPosts: "عدد المنشورات",
      newPosts: "مشاركات جديدة",
      noNews: "لم تنشر الإدارة أي أخبار بعد",
    },
    editProfile: {
      fname: "اسم",
      lname: "اللقب",
      birthDay: "يوم الميلاد",
      medicalNote: "مذكرة طبية",
      requiredDateOfBirth: "تاريخ الميلاد حقل مطلوب",
      photo: "الصورة",
      father: "اسم الاب",
      mother: "اسم الام",
      motherPhone: "رقم هاتف الام",
      fatherPhone: "  رقم هاتف الاب",
      save: "تسجيل التغييرات",
      discard: "الغاء",
      wait: "انتظر قليلا ",
      edit: "تغيير المعلومات الشخصية",
      editChild: "تغيير المعلومات الطفل الشخصية",
      allowedFiles: " ",
      motherJob: " مهنة الام",
      fatherJob: " مهنة الاب",
      adress: "العنوان",
      invalidEmail: "البريد الالكتروني غير صحيح",
      requiredEmail: "البريد الالكتروني مطلوب",
      requiredSchool: "أسم المدرسة مطلوب",
      confirmPassword: "تأكيد كلمة المرور",
      password: "كلمة المرور",
      resetPassword: "إعادة تعيين كلمة المرور",
      passwordRule:
        "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وأن تحتوي على رموز",

      requiredFirstname: "الإسم الأول مطلوب",
      requiredLastname: "اللقب مطلوب",
      requiredPhone: "رقم الهاتف مطلوب",
      requiredAddress: "العنوان مطلوب",
      invalidPhone: "يجب ان يتكون رقم الهاتف من 8 ارقام على الاقل",

      currentPassword: "كلمة المرور الحالية",
      newPassword: "كلمة المرور الجديدة",
      confirmNewPassword: "تأكيد كلمة المرور الجديدة",
      updatePassword: "تحديث كلمة المرور",
      requiredCurrentPassword: "كلمة المرور الحالية مطلوبة",
      requiredNewPassword: "كلمة المرور الجديدة مطلوبة",
      requiredConfirmPassword: "تأكيد كلمة المرور مطلوب",
      passwordConfirmationCondition:
        "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
      passwordNewCondition: "كلمة المرور يجب ان تحتوي على 4 أحرف أو اكثر",
      passwordMustMatch: "كلمة المرور يجب أن تطابق",
      passwordUpdated: "!تم تحديث كلمة المرور بنجاح",
      requestedNameChanges: "تتم مراجعة تغييرات اسم طفلك المطلوبة من قبل أحد المسؤولين",
      changesRequested: "التغييرات المطلوبة على",
    },
    dashboard: {
      dashboard: "إستقبال",
      phoneNumber: "رقم الهاتف",
      headMaster: "المدير",
      adress: "العنوان",
      building: "المدرسة",
      settings: "الإعدادات",
      consultpayment: "استشر الدفع",
      mychild: "ابني",
      details: "تفاصيل",
      canteen: "مطعم مدرسي",
      payment: "دفع",
      actualites: "مستجدات",
      comments: "التعليقات",
      likes: "الإعجابات",
      report: "بطاقة الأعداد",
      reportPeda: "بطاقة الأعداد البيداغوجيّة",
      reportSpec: "بطاقة الأعداد النموذجيّة",
      reportVerySpec: "بطاقة الأعداد المخصصّة",
      reportPedaAnnuelle: "بطاقة الأعداد البيداغوجيّة السنويّة",
      reportSpecAnnuelle: "بطاقة الأعداد النموذجيّة السنويّة",
      reportVerySpecAnnuelle: "بطاقة الأعداد المخصصّة السنويّة",
      trimestrielle: "الثلاثيّات",
      annuelle: "السنويّة",
      bulletinAnnuelle: "بطاقة الأعداد السنويّة",
      avertissements: "تحذيرات",
      attendance: "الحضور",
      schedule: "جدول أوقات",
      homeworks: "واجبات منزلية",
      courses: "الدروس",
      profile: "ملفي",
      language: "لغة",
      signout: "تسجيل خروج",
      arabic: "العربية",
      english: "الإنجليزية",
      french: "الفرنسية",
      studentSpace: "فضاء الطالب",
    },
    report: {
      informations:
        ".ستضيف الإدارة النصوص في نهاية كل فصل دراسي حتى تتمكن من مراقبة مستوى طفلك",
      update: "التحديث الاخير: ",
      premsem: "الفصل الأول",
      deuxsem: "الفصل الثاني",
      troisem: " الفصل الثالث ",
      download: "تحميل",
      unavailable: "!بطاقة الأعداد غير متوفرة في الوقت الحالي",
      yourStudentMark: "العدد المتحصل\n عليه طفلك",
      maxMoyenne: "أعلى معدل",
      minMoyenne: "أدنى معدل",
      maxMark: "أعلى عدد\n في القسم",
      minMark: "أدنى عدد\n في القسم",

      moyenneTrimestre: "معدل الثلاثي : {moyenne}",
      moyenneMax: "أعلى معدل : {moyenne}",
      moyenneMin: "أدنى معدل : {moyenne}",
      performancesNotes: "أداء الدرجات",
      classroomName: "اسم الفصل",
      nbrStudents: "عدد التلاميذ",

      studentBulletinFinal: "اسم التلميذ",
      moyenneTrimester1: "معدل الثلاثّي الأول",
      moyenneTrimester2: "معدل الثلاثّي الثّاني",
      moyenneTrimester3: "معدل الثلاثّي الثّالث",
      moyenneGeneral: "معدل العام",
      bulletinFinalResult: "قرار المجلس",
    },
    canteen: {
      canteen: "مطعم مدرسي",
      informations:
        "يحتاج تلاميذ المدارس والمراهقون إلى نظام غذائي جيد من أجل النمو والتطور والحماية من الأمراض والحصول على الطاقة اللازمة للدراسة والتعلم وأن ينشطوا بدنيًا.",
      informations2: "ستضيف الإدارة أطباق كل يوم",
      choosedate: "اختر التاريخ",
      unavailable: "البيانات غير متوفرة",
      forthisdate: "لهذا التاريخ ",
      schooloffers: " مدرستنا تقدم",
    },
    schedule: {
      informations: "ستضيف الإدارة الجدول الزمني لطفلك لكل فصل دراسي",
      unavailable: "جدول غير متوفر",
    },

    avertissement: {
      teacher: "أستاذ",
      subject: "المادة",
      reason: "المبرر",
      totalnumber: "الرقم الإجمالي",
      notes: "ملاحظات",
      importantInformations: "معلومات مهمة!",
      informations:
        "تساعد العقوبات على عدم استبعاد الفصل بل على الاندماج فيه: فهي تعترف بمسؤولية التلميذ عن أفعاله ، وفي نفس الوقت ، تسمح له بالعودة إلى المجموعة التي استبعد منها نفسه. وهذا ما تؤكده إدارتنا",
      informations2: "لمزيد من المعلومات ، يرجى الاتصال بالادارة",
      unavailable: "لا تحذيرات",
    },
    payment: {
      scolarityPayment: "دفع المدرسة",
      activityPayment: "دفع الأنشطة",
      finalStatus: "الوضع النهائي",
      month: "شهر",
      state: "الحالة",
      activityName: "اسم النشاط",
      fees: "التكاليف",
      progress: "تقدم الدفع",
      singlePayment: "دفع مرة واحدة",
      monthlyPayment: " الدفع الشهري",
      monthlyFees: "رسوم شهرية",
      payThisMonth: "المتبقي للدفع هذا الشهر",
      totalPaid: "مجموع المبالغ المدفوعة",
      afterReduction: "بعد التخفيض",
      inscriptionFees: "رسوم التسجيل",
      inscriptionReduction: "تخفيض التسجيل",
      monthlyReduction: "تخفيض شهري",
      monthly: "شهريا",
      inscription: "تسجيل",
      print: "طباعة",
    },
    settings: {
      options: "الخيارات",
      child: "الطفل",
      year: "سنة دراسية",
      apply: "تطبيق",
      reset: "إلغاء",
      chooseChild: "اختر من بين أطفالك",
      chooseYear: "اختر العام الدراسي",
      trimester: "اختر الثلاثي",
    },
    calendarApp: "اليوميات",

    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
    onlinePayment: {
      success: "تم الدفع بنجاح!",
      thanksMsg: "شكرًا لشرائك، ستتلقى وصولك عبر الرسائل النصية القصيرة.",
      successMsg: "تمت معالجة دفعتك بنجاح.",
      goBackBtn: "العودة إلى كتبي",
      failure: "فشل الدفع",
      failureMsg: "نأسف، لم تتم معالجة دفعتك.",
      failureRetry: "حدثت مشكلة في دفعتك. يرجى المحاولة مرة أخرى.",
      retryPaiment: "أعد المحاولة",
      orderId: "رقم الطلب :"

    }
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "fr",
  globalInjection: true,
  messages,
});

export default i18n;
